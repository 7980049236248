import React from 'react';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';

interface ContractStatusBadgeProps {
  envelope?: CRM.DocusignEnvelope | undefined;
  oneFlowContract?: CRM.OneFlowContract | undefined
}

const badges: Record<'sent' | 'completed', React.ReactElement> = {
  sent: (
      <b title='E-CONTRAT NON SIGNÉ'>
      <AssignmentLateIcon style={{ fontSize: "32px", color: '#ff7734' }} />
    </b>
  ),
  completed: (
    <b title='E-CONTRAT SIGNÉ'>
    <AssignmentTurnedInIcon style={{ fontSize: "32px", color: '#6BCE34' }} />
  </b>
  ),
  
};

export function ContractStatusBadge({ envelope, oneFlowContract }: ContractStatusBadgeProps) {
  const status = envelope ? (envelope.status !== 'completed' ? 'sent' : 'completed') : 
    oneFlowContract ? oneFlowContract.state !== 'signed' ? 'sent' : 'completed' : 'sent';

  return badges[status];
}
