import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { Box, Checkbox, Divider, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, Theme, Typography, makeStyles, useTheme } from '@material-ui/core';
import { useReservation, useReservationActivity } from './reservation.context';
import {
  countBookingPeople,
  isPositive,
  paymentStatus,
  roundMoney,
  sumPaymentReceipts,
  sumReceipts,
} from '@crm/utils';
import { isEmpty } from 'lodash';
import { SaveReservationButton } from './saveButton.component';
import { useAuthUsers } from '../../auth/auth.context';
import { PaymentReceiptsForm } from './paymentReceipts.form';
import { PositiveNumberField } from '../../components/fields/PositiveNumberField.component';
import { FormTitle } from '../../components/FormTitle.component';
import { PaymentStatusBadge } from '../components/paymentStatusBadge.component';
import { PaymentRefundsForm } from './paymentRefunds.form';
import { ContractStatusBadge } from '../components/contractStatusBadge.component';

const useStyles = makeStyles((theme: Theme) => ({
  method: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 10,
    marginBottom: '1rem',
    width: '50%',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const PaymentForm: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { getUser } = useAuthUsers();
  const { reservation, groupsPrice, update } = useReservation();
  const { activity } = useReservationActivity();
  const { payment } = reservation;

  const updateDiscount = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      update({
        payment: {
          ...(payment as CRM.ReservationPayment),
          discount: Number(event.target.value),
        },
      });
    },
    [update, payment],
  );

  const updateOptionalStatus = useCallback(
    (event: ChangeEvent<HTMLInputElement> | undefined) => {
      update({
        payment: {
          ...(payment as CRM.ReservationPayment),
          optionalStatus: event?.target.value as CRM.ReservationPaiementStatus | undefined,
        },
      });
    },
    [update, payment],
  );

  const updateInstructorSelectedPrice = useCallback(
    (price: number) => {
      update({options: {
        ...reservation.options,
        instructorSelectedPriceInEuro: price
      },
    })
    },
    [update, reservation.options]
  );

  useEffect(() => {
    if(
      (!reservation.options || (reservation.options && !reservation.options.instructorSelectedPriceInEuro)) && 
      (activity?.instructorAvailablePricesInEuro && activity?.instructorAvailablePricesInEuro.length > 0)) {
      updateInstructorSelectedPrice(activity?.instructorAvailablePricesInEuro[0])
    }
  },[updateInstructorSelectedPrice, reservation.options, activity?.instructorAvailablePricesInEuro]);

  if (!activity || !groupsPrice) {
    return null;
  }
  if (!reservation.people || countBookingPeople(reservation.people) < 1) {
    return null;
  }

  const sellerName = getUser(reservation.sellerUser)?.displayName || '';
  const alreadyPaid = roundMoney(
    sumPaymentReceipts(reservation.payment) -
      sumReceipts(reservation.payment?.refunds),
  );
  const refund = sumReceipts(reservation.payment?.refunds || []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormTitle>Récapitulatif de la commande</FormTitle>
      </Grid>
      {sellerName && (
        <Grid item xs={12}>
          Vendeur: {sellerName}
        </Grid>
      )}
      <Grid item xs={12}>
        <Box
          boxShadow={3}
          borderRadius={4}
          bgcolor={theme.palette.grey['300']}
          p={3}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={'ellipsis'}>
                {activity.displayName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {groupsPrice.groups
              .filter(group => group.count > 0)
              .map(group => (
                <Grid item xs={12} key={group.customId}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography className={'ellipsis'}>
                      <b>{group.count}</b> x {group.label.fr} ({group.price}€)
                    </Typography>
                    <Typography>{group.sum}€</Typography>
                  </Box>
                </Grid>
              ))}
            {!isEmpty(reservation.options?.instructors) && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography>
                      <b>Option moniteur</b>
                    </Typography>
                    <Typography>
                      <Select
                      labelId='price-select-label'
                      label='choix du tarif'
                      disabled={
                        !activity.instructorAvailablePricesInEuro || 
                        (activity.instructorAvailablePricesInEuro && activity.instructorAvailablePricesInEuro.length === 0)
                      }
                      value={
                        reservation.options?.instructorSelectedPriceInEuro || 
                        ( activity.instructorAvailablePricesInEuro && 
                          activity.instructorAvailablePricesInEuro.length > 0 && 
                          activity.instructorAvailablePricesInEuro[0] ) ||
                        activity.instructorPriceInEuro
                      }
                      onChange={event => updateInstructorSelectedPrice(Number(event.target.value))}
                      >
                        {activity.instructorAvailablePricesInEuro?.map(price => (
                          <MenuItem key={price} value={price}>
                            {price}€
                          </MenuItem>
                        ))}
                      </Select>
                    </Typography>
                    <Typography>
                      {reservation.options!.instructors!.length *
                        (reservation.options?.instructorSelectedPriceInEuro || activity.instructorPriceInEuro)}
                      €
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {isPositive(refund) && (
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Box mr={3}>
                    <Typography>Remboursement:{'  '}</Typography>
                  </Box>
                  <Typography>-{refund}€</Typography>
                </Box>
              </Grid>
            )}
            {isPositive(reservation.payment?.discount) && (
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Box mr={3}>
                    <Typography>Réduction:{'  '}</Typography>
                  </Box>
                  <Typography>-{reservation.payment!.discount}€</Typography>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Box mr={3}>
                  <Typography>Total TTC:{'  '}</Typography>
                </Box>
                <Typography>
                  {reservation.payment?.amountInEuro || '...'}€
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item container spacing={3} xs={12}>
            <Grid item xs={6}>
              <FormTitle>Statut Contrat</FormTitle>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'right', paddingRight:'0'}}>
            <ContractStatusBadge oneFlowContract={reservation.documents?.oneFlowContract} /*envelope={reservation.documents?.docusignEnvelope}*/ />
            </Grid>
          </Grid>
      {reservation.payment && (
        <>
          <Grid item container spacing={3} xs={12}>
            <Grid item xs={6}>
              <FormTitle>Encaissements</FormTitle>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'right', paddingRight:'0'}}>
              {reservation.payment && (
                <PaymentStatusBadge payment={reservation.payment} />
              )}
            </Grid>
          </Grid>
          { reservation.status !== 'cancelled' && reservation.status !== 'gift-voucher' && (
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <FormControlLabel
                label={"Paiement en attente"}
                labelPlacement="end"
                value={'waitToPaid' as CRM.ReservationPaiementStatus}
                disabled={
                  paymentStatus(reservation.payment) === 'paid' ||
                  (
                    reservation.payment.optionalStatus !== 'waitToPaid' &&
                    reservation.payment.optionalStatus !== undefined
                  )
                }
                control={
                  <Checkbox
                    color="secondary"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.checked) {
                        updateOptionalStatus(event);
                      } else {
                        updateOptionalStatus(undefined);
                      }
                    }}
                    checked={paymentStatus(reservation.payment) === 'waitToPaid'}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label={"Remboursement partiel"}
                labelPlacement="end"
                value={'needRefund' as CRM.ReservationPaiementStatus}
                disabled={
                  (paymentStatus(reservation.payment) !== 'partial' &&
                  paymentStatus(reservation.payment) !== 'needRefund' &&
                  paymentStatus(reservation.payment) !== 'paid') ||
                  (
                    reservation.payment.optionalStatus === 'waitToPaid'
                  )
                }
                control={
                  <Checkbox
                    color="secondary"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.checked) {
                        updateOptionalStatus(event);
                      } else {
                        updateOptionalStatus(undefined);
                      }
                    }}
                    checked={paymentStatus(reservation.payment) === 'needRefund'}
                  />
                }
              />
            </Grid>
          </Grid>
          )}
          <Grid item xs={12}>
            <PositiveNumberField
              fullWidth
              label="Réduction"
              variant="outlined"
              value={reservation.payment.discount}
              onChange={updateDiscount}
            />
          </Grid>
          <Grid item xs={12}>
            <PaymentReceiptsForm />
          </Grid>
          <Grid item xs={12}>
            <PaymentRefundsForm />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant={'h6'}>Déjà payé</Typography>
              <Typography variant={'h6'}>
                <b>{alreadyPaid}€</b>
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant={'h6'}>Reste à payer</Typography>
              <Typography variant={'h6'}>
                <b>
                  {Math.max(
                    0,
                    roundMoney(reservation.payment.amountInEuro - alreadyPaid),
                  )}
                  €
                </b>
              </Typography>
            </Box>
            {
              (reservation.status && (reservation.status === "cancelled" || reservation.payment?.optionalStatus === 'needRefund')) &&
              (<Box display="flex" justifyContent="space-between">
                <RadioGroup
                  aria-label="Refund status"
                  value={reservation.payment.optionalStatus || "needRefund"}
                  onChange={updateOptionalStatus}
                  style={{width: '100%', display: 'inline', alignItems: 'center', marginTop: '1em'}}
                >
                  <Box className={classes.method}>
                    <div className={classes.label}>
                      <Radio color="secondary" value="needRefund" />
                      <span>A rembourser</span>
                    </div>
                  </Box>
                  <Box className={classes.method}>
                    <div className={classes.label}>
                      <Radio color="secondary" value="refunded" />
                      <span>Remboursée</span>
                    </div>
                  </Box>
                </RadioGroup>
              </Box>)
            }
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <SaveReservationButton />
      </Grid>
    </Grid>
  );
};
